<template>
   <div class="no-touch" >
         
    <!-- <div>
      <img :src="ItemValue.Logo" alt="" class="logoImg">
    </div> -->


    <div style="padding: 5px;padding-bottom: 5px;font-size: 18px;font-weight: bold;">{{InsureSchoolItemName}}</div>

    <div class="table">

      <div class="row ">
        <div class="cellWidth">方案选择</div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
            <div >{{item.Title}}</div>
        </div>

      </div>

      <div class="row">
        <div class="cellWidth">保费(元/天)</div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
          <div >￥{{item.DayPrice}}</div>
        </div>

      </div>

      <div class="row">
        <div class="cellWidth">
          <div>校外意外医疗</div>
          <div>(门诊+住院)</div>

        </div>

        <div :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
            <div >{{item.IAAccidentTeatOutS}}</div>
        </div>

      </div>
      
      <div class="row">
        <div class="cellWidth">
          <div>校内意外医疗</div>
          <div>(门诊+住院)</div>

        </div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
          <div >{{item.IAAccidentTreatInS}}</div>
        </div>

      </div>

      <div class="row">
        <div class="cellWidth">
          <div>校外意外身故</div>
          <div>全残</div>
        </div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
          <div >{{item.IAAccidentDeathOutS}}</div>
        </div>
        
      </div>


      <div class="row">
        <div class="cellWidth">
          <div>校内意外身故</div>
          <div>全残</div>
        </div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
          <div >{{item.IAAccidentDeathInS}}</div>
        </div>

      </div>

      <div class="row">
        <div class="cellWidth">
          <div>疾病住院</div>
          <div>(60日等待期内)</div>
        </div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
          <div >{{item.IASicknessTreatAmIN60}}</div>
        </div>

      </div>

      <div class="row">
        <div class="cellWidth">
          <div>疾病住院</div>
          <div>(60日等待期后)</div>
        </div>

        <div   :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
          <div >{{item.IASicknessTreatAm}}</div>
        </div>
        
      </div>

      <div class="row">
        <div class="cellWidth">
          <div>疾病身故</div>
          <div>(60日等待期内)</div>
        </div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
            <div >{{item.IASicknessDeathIN60}}</div>
        </div>

      </div>



      <div class="row">
        <div class="cellWidth">
          <div>疾病身故</div>
          <div>(60日等待期后)</div>
        </div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
            <div >{{item.IASicknessDeath}}</div>
        </div>

      </div>


      <div class="row">
        <div class="cellWidth">
          <div>意外医疗</div>
          <div>赔付比例</div>
        </div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
            <div style="color: red;">100%</div>
        </div>

      </div>

      <div class="row">
        <div class="cellWidth">
          <div>疾病住院赔付比例</div>
          <div>(60日等待期后)</div>
        </div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
            <div >{{item.IASicknessTreatPer}}</div>
        </div>

      </div>

      <div class="row">
        <div class="cellWidth">
          <div>疾病住院赔付比例</div>
          <div>(60日等待期内)</div>
        </div>

        <div  :class="{'cell':true ,'cellBackColor': SelectIndex == index , 'cellPadding':SelectIndex != index }" v-for="(item,index) in list" :key="index" @click="getSelectItem(index)">
            <div >{{item.IASicknessTreatPerIN60}}</div>
        </div>

      </div>

   </div>


    <div class="insureTitle">

      <div class="titleCP">
         <span>承保年龄</span>
         <span>{{ItemValue.AgeRange}}</span>
      </div>

      <div class="titleCP">
         <span>承保及理赔单位:</span>
         <span>{{ItemValue.ResponsibleCom}}</span>
      </div>
      
    </div>


    <!-- <div class="dutydescribe">
      <div class="duty_title">
        保障责任
        <span style="font-size: 18px;font-weight: normal;position: absolute;right: 22%;">赔付比例</span>
        <span style="font-size: 18px;font-weight: normal;position: absolute;right: 10%;">保额</span>
      </div>

      <div class="duty_health">
         <span>意外医疗</span>
         <span class="duty_item1">校外</span>
         <span style="color:red;position: absolute;right: 22%;margin-right: 15px;">100%</span>
         <span class="duty_fee1">{{ItemValue.IAAccidentTeatOutS}}</span>

      </div>

      <div class="duty_health">
         <span>(门诊+住院)</span>

         <span class="duty_item1">校内</span>
         <span style="color:red;position: absolute;right: 22%;margin-right: 15px;">100%</span>
         <span class="duty_fee1">{{ItemValue.IAAccidentTreatInS}}</span>
      </div>

      <div class="duty_death">
         <span >意外身故/全残</span>
         <span class="duty_item1">校外</span>
         <span class="duty_fee1">{{ItemValue.IAAccidentDeathOutS}}</span>
         <span class="duty_item2">校内</span>
         <span class="duty_fee2">{{ItemValue.IAAccidentDeathInS}}</span>
      </div>

    </div>


    <div class="duty_death_all">
      <span>疾病身故(60 日等待期后)</span>
      <span class="duty_fee1">{{ItemValue.IASicknessDeath}}</span>
    </div>

    <div class="duty_death_all">
      <span>疾病身故(60 日等待期内)</span>
      <span class="duty_fee1">{{ItemValue.IASicknessDeathIN60}}</span>
    </div>
    <div class="illness">
      <span>疾病住院(60 日等待期后)</span>
      <span style="color: red;position: absolute;right: 22%;margin-right: 15px;">{{ItemValue.IASicknessTreatPer}}</span>
      <span class="duty_fee1">{{ItemValue.IASicknessTreatAm}}</span>
    </div>

    <div class="illness">
      <span>疾病住院(60 日等待期内)</span>
      <span style="color: red;position: absolute;right: 22%;margin-right: 15px;">{{ItemValue.IASicknessTreatPerIN60}}</span>
      <span class="duty_fee1">{{ItemValue.IASicknessTreatAmIN60}}
      </span>
    </div> -->


    <div class="protocol" @click="InsureNotice">
      <span style="font-weight: bold;">投保须知</span>
      <span>></span>
    </div>

    <div class="protocol" @click="InsureNoticeList">
      <span style="font-weight: bold;">保险条款</span>
      <span>></span>
    </div>

    <InsureProtocol 
    :ClickCloseInsure="ClickClose" 
    :hidden="ShowInsureProtocol" 
    :DocList="DocList"
    :ShowDocList="ShowDocList"
    :html="html"
    ></InsureProtocol>


   </div>

</template>
<script>
import InsureProtocol from "./InsureProtocol.vue"

import { MessageBox } from 'mint-ui';
export default {
  props: ['getInsureValue','getInsureProtocol','showInSureSuccess'],
  components: {
    InsureProtocol
  },
  data() {
      return {
        pickerValue:'',
        InsureSchoolItemName:'',
        ItemValue:{},
        ShowInsureProtocol:true,
        DocList:[],
        InsureProtocolDocList:[],
        InsureDocList:[],
        ShowDocList:true,
        html:"",
        InsureItemID:"",
        sheetVisible:false,
        list:[],
        SelectIndex : -1,
        reSchoolItemID:""
      }
   },
   created(){

    const params = new URLSearchParams(window.location.search);
    var that = this;
    let SchoolItemID = params.get("SchoolID");
    let UserID =  params.get("UserID");

    // let SchoolItemID = "14021DBF-1B1C-4DD5-B010-BCC461843A82";
    // let UserID =  "";


    var url = 'https://api.yalijy.com/api/Insure/GetInsureOnlineDetais?'+'SchoolID='+SchoolItemID+'&UserID='+UserID
    this.$axios.get(url).then(function (resoponse) {

      
      that.list = resoponse.data.data.Items;
      that.InsureSchoolItemName = resoponse.data.data.InsureSchoolItemName

      that.reSchoolItemID = resoponse.data.data.SchoolItemID

      that.sheetData = []
      for (let item in that.list)
      {

        let data = that.list[item]
      
        if (data.IsDefault == 1)
        {
          that.SelectIndex = item
          that.ItemValue = data
          that.InsureItemID = that.ItemValue.InsureItemID
          that.getInsureValue(that.ItemValue.YearPrice,that.InsureItemID,that.reSchoolItemID)
          break
        }
      }

      that.showInSureSuccess();
      that.GetDocTerms();

      // that.sheetVisible = true

      // if (list.length > 0 && list != undefined) {
      //   that.showInSureSuccess();
      //   that.ItemValue = list[0];
      //   that.getInsureValue(that.ItemValue.Title,that.ItemValue.Price,that.ItemValue.Unit)
      // }else{
      //   MessageBox.alert('数据有误').then(function (){});
      // }

      // that.GetDocNotice()

    }).catch(function (error){
      console.log(error);
      MessageBox.alert('数据有误').then(function (){});
    })

    // this.$axios.get('https://api.yalijy.com/API/Insure/GetItemTitle',
    // {
    //   params:{
    //     SchoolItemID:params.get("SchoolItemID"),
    //     InsureItemID:params.get("InsureItemID")
    //   }
    // }).then(function (resoponse) {

    //   var list = [];
    //   list = resoponse.data.data;
    //   console.log(list);

    //   if (list.length > 0 && list != undefined) {
    //     that.showInSureSuccess();
    //     that.ItemValue = list[0];
    //     that.getInsureValue(that.ItemValue.Title,that.ItemValue.Price,that.ItemValue.Unit)
    //   }else{
    //     MessageBox.alert('数据有误').then(function (){});
    //   }

    //   that.GetDocNotice()

    // }).catch(function (error){
    //   console.log(error);
    //   MessageBox.alert('数据有误').then(function (){});
    // })



    
   },
   computed:{
   },
   methods:{
    ShowSheet(){
      this.sheetVisible = true
    },
    getSelectItem(e){
      console.log(e);
      this.SelectIndex = e
      this.ItemValue = this.list[e]
      this.InsureItemID = this.ItemValue.InsureItemID
      this.getInsureValue(this.ItemValue.YearPrice,this.InsureItemID,this.reSchoolItemID)
    },
    GetDocTerms(){
      // 投保
      var that = this;
      that.$axios.get('https://api.yalijy.com/API/Insure/GetDocTerms?'+"ItemID="+this.InsureItemID,
      {}).then(function (resoponse) {

        console.log('----GetDocTerms')
        console.log(resoponse.data.data)
        that.DocList =  resoponse.data.data;
        that.InsureDocList = resoponse.data.data;

        for (let item in that.DocList)
        {
          let data = that.DocList[item];
          if (data.ShowInList == "Y")
          {
            that.InsureProtocolDocList.push(data)
          }
        }
        // that.getInsureProtocol(that.DocList);

      }).catch(function (error){
        console.log("---GetDocTerms")

        console.log(error);
      })

    },
    GetDocNotice(){
      
      const params = new URLSearchParams(window.location.search);
      let that = this;

      that.$axios.get('https://api.yalijy.com/API/Insure/GetDocNotice?'+"ItemID="+params.get("InsureItemID"),
      {
      }).then(function (resoponse) {

        console.log('1222')
        console.log(resoponse)

        that.html = resoponse.data.data

      }).catch(function (error){
      console.log(error);
      })

    },
    handleConfirm(){
        this.$refs.picker.open();
    },
    InsureNotice(){
      this.stopScroll();
      this.DocList = this.InsureDocList
      for (let item in this.InsureDocList)
        {
          let data = this.InsureDocList[item];
          if (data.DocName == "投保须知")
          {
            this.html = data.DocURL
            break
          }
        }
      this.ShowDocList = true;
      this.ShowInsureProtocol = false;
    },
    ClickClose(){
      this.DocList = this.InsureDocList
      this.ShowDocList = true;
      this.ShowInsureProtocol = true;
    },
    InsureNoticeList()
    {
      this.stopScroll();
      this.html = "";
      this.DocList =  this.InsureProtocolDocList
      this.ShowDocList = false;
      this.ShowInsureProtocol = false;
    }
   },
}
</script>
<style scoped>

.no-touch {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.logoImg {
   width:95%;
   height:400px;
   /* margin-left: 5%;
   margin-right: 5%; */
}

.insureTitle {
   font-size: 15px;
   margin-top: 10px;
   margin-bottom: 10px;
}

.insureTitle .titleCP {
   display: flex;
   justify-content: space-between;
   margin-bottom: 10px;
   margin-left: 20px;
   margin-right: 20px;
}


.dutydescribe {
    font-size: 15px;
}

.dutydescribe .duty_title {
    display: flex;
    margin: 10px;
    font-size: 20px;
    font-weight: bold;
}

.dutydescribe .duty_health {
    display: flex;
    margin-left: 20px;
    padding-bottom:  40px;
}

.duty_item1
{
    position: absolute;
    left: 60%;
}
.duty_fee1
{
    position: absolute;
    right: 10%;
    color: red;

}

.duty_item2
{
    position: absolute;
    left: 60%;
    margin-top: 30px;
}
.duty_fee2
{
    position: absolute;
    right: 10%;
    margin-top: 30px;
    color: red;


}

.duty_death 
{
    display: flex;
    margin-left: 20px;
    padding-bottom:  40px;
}

.duty_item1
{
    position: absolute;
    left: 50%;
}

.duty_fee1
{
    position: absolute;
    right: 10%;
    color: red;

}


.duty_item2
{
    position: absolute;
    left: 50%;
    margin-top: 30px;
}

.duty_fee2
{
    position: absolute;
    right: 10%;
    margin-top: 30px;
    color: red;

}

.duty_death_all
{
    font-size: 15px;
    display: flex;
    margin-left: 20px;
    /* margin-right: 9%; */
    justify-content: space-between;
    padding-bottom: 10px;
}

.illness
{
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 9%;
  margin-top: 10px;
  margin-bottom: 30px;
}

.protocol
{
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 10%;
  color: blue;
}

.table {
  font-size: 11px;
  display: flex;
  flex-direction: column;
  /* height: 100vh; 或者设置一个适当的高度 */
}

.row {
  display: flex;
  /* width: 100%; */
}

.cell {
  flex: 1;
  border: 1px solid gray;
}

.cellWidth {
  width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid gray;
}


.cellBackColor {
  display: flex;
  align-items: center; 
  justify-content: center; 
  background-color: lightgreen;
}

.cellPadding {
  display: flex;
  align-items: center; 
  justify-content: center; 
}

</style>
