<template>
    <div class="no-touch">

        <div class="ReadTitle">本人已充分了解本产品，阅读并同意以下内容</div>
        <div class="ReadSubtitle">请分别确认产品文档</div>

        <div  class="RulesList" v-for="(item,index) in DocList"
            :key="index">

            <span class="RulesTitle" @click="ClickReadProtocol(index)">{{item.DocName}}</span>

            <span class="RulesCheck" @click="SelectItem(index)">
                <img v-if="item.check == true" class="RulesImg" src="../assets/check_square.png" alt="">
                <img v-else class="RulesImg" src="../assets/none_square.png" alt="">
            </span>

        </div>


        <InsureProtocol 
        :ClickCloseInsure="ClickClose" 
        :hidden="ShowInsureProtocol" 
        :html="html"
        ></InsureProtocol>

    </div>
</template>
<script>
import InsureProtocol from "./InsureProtocol.vue"
export default {
   props: {
    showInsureFrom:{
        type:Function,
        required:true
    }
   },
   components: {
    InsureProtocol
   },
   data() {
      return {
        DocList:[],
        ShowInsureProtocol:true,
        html:"",
        InsureItemID:""
      }
   },
   created(){
   },
   computed:{
   },
   methods:{
    initData(ItemID) {

        // const params = new URLSearchParams(window.location.search);
        let that = this;
        that.InsureItemID = ItemID
        console.log("=----==");
        console.log(that.InsureItemID);
        that.$axios.get('https://api.yalijy.com/API/Insure/GetDocTerms?'+"ItemID="+that.InsureItemID,
        {
        }).then(function (resoponse) {

         console.log('1222')
         console.log(resoponse)
         if (that.DocList.length != 0)
         {
            that.DocList = []
         }
            for (let item in resoponse.data.data)
            {
                let data = resoponse.data.data[item];
                data.check = false;
                that.DocList.push(data);
                console.log(that.DocList)

            }

        }).catch(function (error){
        console.log(error);
        })
    },
    SelectItem(index) {
        
        let data = this.DocList[index];
        if (data.check == true)
        {
            data.check = false
        }else if (data.check == false)
        {
            data.check = true
        }
        this.DocList[index] = data;
        this.CheckSelectItem()
    },
    CheckSelectItem(){
        var all = true;
        

        this.DocList.forEach((value) => {
            if (value.check == false) {
                all = false;
            }
        });

        if (all) {
            this.showInsureFrom();
        }

    },
    ClickReadProtocol(index) {
        let data = this.DocList[index];
        this.html = data.DocURL;
        this.ShowInsureProtocol = false;
    },
    ClickClose(){
      this.ShowInsureProtocol = true;
    },
   },
}
</script>
<style scoped>

.no-touch {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ReadTitle
{
    display: flex;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;

}

.ReadSubtitle
{
    display: flex;
    margin: 10px;
    font-size: 16px;
    margin-bottom: 10px;
}

.RulesList
{
    display: flex;
    justify-content: space-between;
    margin: 10px;
    margin-top: 15px;
}

.RulesTitle
{
    width: 90%;
    text-align: left;
    font-size: 15px;
    color: red;
}

.RulesCheck .RulesImg
{
    width: 30px;
    height: 30px;
}

</style>
