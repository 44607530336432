<template>
    <div class="no-touch">

        <div class="ProtocolBG" style="overflow-y:hidden">
        </div>


        <div class="content" >
        <div class="operate">
            <div class="operateItem1" :hidden="showBack" @click="goback">返回</div>
            <div class="operateItem2" @click="ClickClose">
                <img src="../assets/close.png" alt="">
            </div>
        </div>

        <div v-if="!ShowDocList && ShowDocContent != 2">
            <div class="DocList" v-for="(item,index) in DocList"
            :key="index"
            @click="ClickShowDoc(index)">
                <div class="DocItem">{{item.DocName}}</div>
                <div  class="DocItem">></div>
            </div>
        </div>
           <iframe :hidden="!(ShowDocContent == 2)" :src="DocURL" class="contentWeb"></iframe> 
           <iframe :hidden="!ShowDocList" :src="html" class="contentWeb"></iframe> 

           <!-- <iframe :hidden="!ShowDocList" src="static/index01.html" class="contentWeb"></iframe>  -->

        </div>

    </div>
</template>
<script>
export default {
   props:{
    ClickCloseInsure: {
        type:Function,
        required:true
    },
    DocList:{
        type:Array,
        default:function () { return [] }
    },
    ShowDocList: {
        type:Boolean,
        default:true
    },
    html:{
       type:String,
       default:""
    }
   },
   data() {
      return {
        ShowDocContent:1,
        showBack:true,
        DocURL:""
      }
   },
   created(){
   },
   computed:{
   },
   mounted () {
   },
   methods:{

    ClickClose() {
        this.ShowDocContent = 1;
        this.showBack = true;
        this.canScroll();
        this.ClickCloseInsure();
    },
    goback(){
        this.ShowDocContent = 1;
        this.showBack = true;
    },
    ClickShowDoc(index){
       let data = this.DocList[index];
       console.log(data);
       this.DocURL = data.DocURL;
       this.ShowDocContent = 2;
       this.showBack = false
   }
  }
}
</script>
<style scoped>

.no-touch {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ProtocolBG
{
    width : 100%;
    height : 100%;
    flex: 1;
    position: fixed;
    top: 0%;
    z-index: 1300;
    top: 0;
    left: 0;
    background-color: black ;
    opacity: 0.4;

}

.content
{
    flex: 1;
    position: fixed;
    top: 0%;
    z-index: 1300;
    top: 12%;
    left: 0;
    background-color: white;
    width : 100%;
    height : 88%;
    opacity: 1;

}

.contentWeb
{
    width : 100%;
    height : 90%;
}

.operate
{
    display: flex;
    justify-content: space-between;
    height: 10%;
}

.operateItem1
{
    margin-top: 10px;
    margin-left: 15px;
    font-size: 16px;
    font-weight: bold;
    color: blue;
}

.operateItem2
{
    position: absolute;
    right: 10px;
}

.operateItem2 img
{
    width: 45px;
    height: 45px;
}

.DocList
{
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.DocItem
{
    padding-bottom: 15px;
} 

</style>
