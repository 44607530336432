<template>
  <div id="app" >

    <!-- <div :hidden="showInsureTitle" class="HomeTitle">{{title}}</div> -->
    <InsureHome 
     :getInsureValue="getInsureValue"
     :hidden="showInsureHome"
     :showInSureSuccess="showInSureSuccess"
     ></InsureHome>

    <ReadInsure 
    ref="ReadInsureView"
    :hidden="showReadInsure"
    :showInsureFrom="clickShowInsureFrom"
    ></ReadInsure>


    <InsureFrom
    ref="InsureFromView"
    :hidden="showInsureFrom"
    :callParentMethod="onStartPlayer"
    ></InsureFrom>

    

    <div style="height:100px;"></div>

    <div class="footercontainer" :hidden="showInsureHome">

      <div class="footerInsureHome">
        <span class="footerInsureHome_price">
          <span class="footerInsureHome_price_item1">￥{{price}}</span>
          <span class="footerInsureHome_price_item2">元/年</span>
        </span>

        <span class="footerInsureHome_operate">
          <span class="footerInsureHome_btn1" @click="helpOthersInsure" v-if="!showInsureMe">帮别人投保</span>
          <span class="footerInsureHome_btn2" @click="oneselfInsure" v-if="showInsureMe">给自己投保</span>
          <span style="margin-left: 40px;"  @click="ShowSheet" v-if="showInsureMe">
            <img style="position: absolute;right: 5px;width: 20px;height: 20px;" src="./assets/upward_icon.jpg" alt="">
          </span>
        </span>

      </div>

    </div>

    <div class="modal" v-if="isOpen">
      <div class="modal-content">
        <img style="width: 150px;height: 150px;" src="../src/assets/TipIcon.jpeg" alt="">
        <div>即将进入投保页面</div>
        <div>请您仔细阅读条款</div>
        <div>为保证您的权益,您在投保过程中的</div>
        <div>操作行为将被完整记录</div>
        <div style="padding:  10px;margin-top: 10px;" class="footerInsureHome_btn1" @click="OpenInsure">好的</div>
      </div>
   </div>


    <!-- <div @click="onStartRecord">开始</div>
    <div @click="onStartPlayer">停止</div> -->

    <!-- <div style="height:200px;"></div>

    <div id="playback">
      
    </div> -->

    <!-- <div style="height:700px;"></div> -->

    <mt-actionsheet
        :actions= "sheetData"
        v-model="sheetVisible">
    </mt-actionsheet>


  </div>
</template>

<script>
import { Indicator } from 'mint-ui';
import { MessageBox } from 'mint-ui';
import { record } from "rrweb"
// import rrwebPlayer from "rrweb-player"
import InsureHome from './components/InsureHome.vue'
import ReadInsure from './components/ReadInsure.vue'
import InsureFrom from "./components/InsureFrom.vue"
// import RecVideo from './components/RecVideo.vue';

export default {
  name: 'App',
  components: {
    InsureHome,
    ReadInsure,
    InsureFrom,
    // RecVideo
  },
  data(){
        return {
          events: [],
          showInsureTitle:false,
          showInsureHome:true,
          showReadInsure:true,
          showInsureFrom:true,
          showInsureMe:false,
          title:"",
          price:"",
          unit:"",
          DocList:[],
          isRRWeb:false,
          isOpen: false,
          isInsure:false,
          sheetData:[{
           name: '帮别人投保',
           method : this.getsheet,
           index: 0}],
           sheetVisible:false,
           SchoolItemID:""
        }
  },
  created(){
     const params = new URLSearchParams(window.location.search);
     let StudentID = params.get("StudentID")
     if (StudentID != undefined || StudentID != null)
     {
      this.showInsureMe = true
     }
  },
  methods: {
    ShowSheet(){
      this.sheetVisible = true
    },
    getsheet(){
      this.helpOthersInsure()
    },
    OpenInsure() {

      document.body.style.overflow = ''
      this.isOpen = false
      
      if (this.isInsure) {
        
        this.showInsureHome = true;
        this.showReadInsure = false;
        this.$refs.InsureFromView.helpInsure = false;
        this.$refs.InsureFromView.initPostData()
        this.$refs.ReadInsureView.initData()

      }else{

        this.showInsureHome = true;
        this.showReadInsure = false;
        this.$refs.InsureFromView.helpInsure = true;
        this.$refs.InsureFromView.clearinitData()
        this.$refs.ReadInsureView.initData()

      }

    },
    showInSureSuccess() {
      this.showInsureHome = false;
    },
    clickShowInsureFrom () {
      this.showReadInsure = true;
      this.showInsureHome = true;
      this.showInsureFrom = false;
    },
    getInsureProtocol(value) {
      this.DocList =  value;
    },
    oneselfInsure() {

    const params = new URLSearchParams(window.location.search);
    let StudentID = params.get("StudentID");

      var that = this;
      that.$axios.get('https://api.yalijy.com/api/Insure/GetEffectiveReday?'+"SchoolItemID="+that.SchoolItemID+'&StudentID='+StudentID,
      {
      }).then(function (resoponse) {

        console.log(resoponse.data)
        if (resoponse.data.flag == 1000)
        {
          that.isOpen = true
          document.body.style.overflow = 'hidden';

          that.isInsure = true
          if (!that.isRRWeb)
          {
            that.isRRWeb = true
            that.onStartRecord();
          }

        }else{
          MessageBox.alert('该学生已投保').then(function (){});
        }


      }).catch(function (error){
        console.log(error);
      })

      
    },

    helpOthersInsure() {

      this.isOpen = true
      document.body.style.overflow = 'hidden';

      this.isInsure = false


      if (!this.isRRWeb)
      {
        this.isRRWeb = true
        this.onStartRecord();
      }
    },

    onStartRecord() {
      var that = this
      new record({
        emit(data) {
          // 用任意方式存储 event
          // console.log(data)
          that.events.push(data)
          // console.log(that.events)
        },
      })
      // alert("正在录制")

      // var that = this;
      // that.$axios.get('https://api.yalijy.com/api/Insure/GetJson',
      //   {}).then(function (resoponse) {

      //       // let events = JSON.parse(resoponse.data);
      //       console.log(resoponse);

      //       // console.log('---');
      //       // that.events = resoponse.data
      //       // console.log(that.events);

      //       let events = resoponse.data;

      //       new rrwebPlayer({
      //         target: document.getElementById("playback"), // 可以自定义 DOM 元素
      //         data: { 
      //           events,
      //           width:'1050',
      //           height:'500',
      //         },
      //       })

      //   }).catch(function (error){
      //     console.log(error);
      //   })
    
    },

    onStartPlayer(value,url) {

      //https://api.yalijy.com/api/Insure/AddJson

      let events = this.events;
      let data = JSON.stringify(events);

      // console.log(data)
      // console.log(value)
      Indicator.open('正在加载...')
      var that = this;
      that.$axios.post('https://api.yalijy.com/api/Insure/AddTrace',
      {
        tranID: value,
        data:data
      }).then(function (resoponse) {

        if(resoponse.data.flag == 1000)
        {
          Indicator.close()
          console.log(resoponse);
          console.log(url)
          console.log(value)
          console.log('===========')
          window.location.href = url;
        }

      }).catch(function (error){
        console.log(error);
      })


      // let events = this.events;


      // new rrwebPlayer({
      //   target: document.getElementById("playback"), // 可以自定义 DOM 元素
      //   data: { 
      //     events,
      //     width:'2050',
      //     height:'500',
      //    },
      // })

    },

    getInsureValue(price,InsureItemID,ItemID) {

      // this.title = title;
      console.log('getInsureValue')
      this.price = price;
      this.InsureItemID = InsureItemID;
      this.SchoolItemID = ItemID;
      this.$refs.ReadInsureView.initData(InsureItemID)
      this.$refs.InsureFromView.initData(InsureItemID,ItemID)
      // this.unit = unit;

    }


  }
}
  
</script>

<style scoped>

.HomeTitle{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.footercontainer
{
  flex: 1;
  position: fixed;
  bottom: 0%;
  /* max-width: 640px;
  min-width: 320px; */
  width: 100%;
  z-index: 1000;
}


.footerInsureHome
{
  display: flex;
  background-color: white;
  height: 60px;
}

.footerInsureHome_price
{
  margin-left: 2%;
  margin-top: 10px;
}

.footerInsureHome_price_item1
{
  font-size: 25px;
  color: red;
  font-weight: bold;
}

.footerInsureHome_price_item2
{
  font-size: 15px;
  font-weight: bold;
  margin-left: 3px;
}

.footerInsureHome_operate
{
  position: absolute;
  right: 4%;
  margin-top: 20px;
}

.footerInsureHome_btn1
{

  background-color: red;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 15px;
  font-weight: bold;
  padding: 5px;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
}

.footerInsureHome_btn2
{
  width: 300px;
  height: 45px;
  background-color: red;
  font-size: 15px;
  font-weight: bold;
  padding: 5px;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;

}

.modal-content {
  background-color: #fff;
  padding: 20px;
}

</style>
