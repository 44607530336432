<template>
    <div class="formList no-touch">

        <div class="draw"></div>

        <div class="separateBigDraw"></div>

        <div class="selectData" @click="openPicker">
            <span>开始保障</span>
            <span>{{ValiDateStart}} ></span>
        </div>

        <div class="separateSmallDraw"></div>

        <div class="selectData" @click="endPicker">
            <span>结束保障</span>
            <span>{{ValiDateEnd}}   </span>
        </div>

        <div class="separateBigDraw"></div>
        

        <div class="InsureTitle">
            <samp class="InsureSubTitle">填写投保人信息</samp>
            <samp>(家长信息)</samp>
        </div>

        <div class="separateSmallDraw"></div>

        <div class="inputView">
            <span><span style="color: red;">*</span>姓名:</span>
            <input type="text" placeholder="请输入姓名" v-model="Aname"/>
        </div>

        <div class="separateSmallDraw"></div>

        <div class="selectView" @click="showSheetVisible(1)">
            <span><span style="color: red;">*</span>证件类型:</span>
            <span>{{AidCardType}}<span style="margin-left: 10px;">></span></span>
        </div>
        <div class="separateSmallDraw"></div>

        <div class="inputView">
            <span><span style="color: red;">*</span>证件号码:</span>
            <input type="text" placeholder="请输入证件号码" v-model="AidCardNo"/>
        </div>

        <div class="separateSmallDraw"></div>

        <div class="inputView">
            <span><span style="color: red;">*</span>手机号码:</span>
            <input type="text" placeholder="请输入手机号码" v-model="Amobile"/>
        </div>
        <div class="separateSmallDraw"></div>

        <div class="separateBigDraw"></div>

        <div class="InsureTitle">
            <samp class="InsureSubTitle">填写被保人信息</samp>
            <samp>(学生信息)</samp>
        </div>

        <div class="separateSmallDraw"></div>

        <div class="inputView">
            <span><span style="color: red;">*</span>姓名:</span>
            <input type="text" placeholder="请输入姓名" v-model="Iname"/>
        </div>

        <div class="separateSmallDraw"></div>

        <div class="selectView" @click="showSheetVisible(2)">
            <span><span style="color: red;">*</span>证件类型:</span>
            <span>{{IidCardType}}<span style="margin-left: 10px;">></span></span>
        </div>

        <div class="separateSmallDraw"></div>

        <div class="inputView">
            <span><span style="color: red;">*</span>证件号码:</span>
            <input type="text" placeholder="请输入证件号码" v-model="IidCardNo" @input="handleInput"/>
        </div>

        <div class="separateSmallDraw"></div>

        <div class="selectView" @click="birthPicker">
            <span><span style="color: red;">*</span>出生日期:</span>
            <span><span style="margin-left: 10px;">{{Ibirthday}}></span></span>
        </div>
        <div class="separateSmallDraw"></div>

        <div class="selectSexView">
            <span><span style="color: red;">*</span>性别:</span>
            <span>
                <span class="sexItem1" @click="SelectSex(1)">
                    <img v-if="IsexState == 1" class="selectSexImg" src="../assets/check_square.png" alt="">
                    <img v-else class="selectSexImg" src="../assets/none_square.png" alt="">
                    <span>男</span>
                </span>
                <span class="sexItem2" @click="SelectSex(2)">
                    <img v-if="IsexState == 2" class="selectSexImg" src="../assets/check_square.png" alt="">
                    <img v-else class="selectSexImg" src="../assets/none_square.png" alt="">
                    <span>女</span>
                </span>
            </span>
        </div>
        <div class="separateSmallDraw"></div>

        <div class="inputView">
            <span><span style="margin-left: 10px;"></span>学校:</span>
            <span style="margin-left: 10px;">{{ISchoolName}}</span>
            <!-- <input type="text" placeholder="学校" v-model="username" readonly="true"/> -->
        </div>

        <div class="separateSmallDraw"></div>

        <div class="inputView">
            <span><span style="margin-left: 10px;"></span>年级:</span>
            <input :readonly=!helpInsure  type="text" placeholder="请输入年级" v-model="Iclass"/>
        </div>

        <div class="separateSmallDraw"></div>

        <div class="separateBigDraw"></div>


        <div class="protocol" @click="ClickCheck">
            <img v-if="Check"  src="../assets/check_square.png" alt="">
            <img v-else src="../assets/none_square.png" alt="">
            <span>
                本人已充分了解保险产品，并承担投保信息的真实
                性，理解并同意 <span style="color: red;">

                <!-- <span @click="ClickReadProtocol(0)">《附加疾病身故保险》</span>、
                <span @click="ClickReadProtocol(0)">《附加学生
                意外伤害医疗保险（互联网版）》</span>、
                <span @click="ClickReadProtocol(0)">《附加学生住院
                医疗保险（互联网版）》</span>、
                <span @click="ClickReadProtocol(0)">《学生幼儿意外伤害保险
                （互联网专属）》</span>  -->
                  
           <span  class="RulesList" v-for="(item,index) in DocList" :key="index">
            <span @click="ClickReadProtocol(index)">  {{item.DocName}}、 </span>
           </span>
                 </span>的全部内容 
            </span>
        </div>


        <div class="separateBigDraw"></div>


        <div style="height:100px;"></div>

        <div class="footercontainer">

        <div class="footerInsureHome">
        <span class="footerInsureHome_price">
            <span class="footerInsureHome_price_item1">￥{{totalPicker}}</span>
            <span class="footerInsureHome_price_item2">元/{{dayCount}}天</span>
        </span>

        <span class="footerInsureHome_operate">
            <span class="footerInsureHome_btn1" @click="ClickInsure">立即投保</span>
        </span>

        </div>

        </div>



        <!-- <div @click="handleConfirm"></div> -->
            <mt-datetime-picker
            ref="startPicker"
            type="date"
            :startDate="startDate" 
            :end-date="endDate"
            v-model="pickerValue1"
            @confirm="handleConfirm">
        </mt-datetime-picker>

        <!-- <div @click="handleConfirm"></div> -->
        <mt-datetime-picker
            ref="endPicker"
            type="date"
            :startDate="endDate" 
            v-model="pickerValue2"
            @confirm="handleConfirm">
        </mt-datetime-picker>


        <!-- <div @click="handleConfirm"></div> -->
        <mt-datetime-picker
            ref="picker"
            type="date"
            :startDate="startBirthDate" 
            :end-date="birthDate"
            v-model="pickerValue3"
            @confirm="handleConfirm">
        </mt-datetime-picker>


     <mt-actionsheet
        :actions= "sheetData"
        v-model="sheetVisible">
     </mt-actionsheet>

    <InsureProtocol 
        :ClickCloseInsure="ClickClose" 
        :hidden="ShowInsureProtocol" 
        :html="html"
        ></InsureProtocol>

    </div>
</template>
<script>

import { MessageBox,Indicator } from 'mint-ui';
import InsureProtocol from "./InsureProtocol.vue"
export default {
   props: ['callParentMethod'],
   components: {
    InsureProtocol
   },
   data() {
      return {
        startDate:new Date(),
        endDate:new Date(),
        startBirthDate:new Date(),
        birthDate:new Date(),
        pickerValue1:"",
        pickerValue2:"",
        pickerValue3:"",
        startPickerValue:"2023-04-04",
        endpickerValue:"2023-04-04",
        birthPickerValue:"2023-04-04",
        pickerState:0,
        sheetData:[{
        name: '居民身份证',
        method : this.getsheet1},
        {
        name: '户口本', 
        method : this.getsheet2},
        {
        name: '护照', 
        method : this.getsheet3},
        {
        name: '台胞证', 
        method : this.getsheet4},
        {
        name: '澳门居民身份', 
        method : this.getsheet5},
        {
        name: '香港居民身份证', 
        method : this.getsheet6}],
        sheetVisible:false,
        username:"",
        ValiDateStart:"",
        ValiDateEnd:"",
        AidCardType:"",
        Aname:"",
        AidCardNo:"",
        Amobile:"",
        Iname:"",
        IidCardType:"",
        IidCardNo:"",
        Ibirthday:"",
        Isex:"",
        ISchoolName:"",
        Iclass:"",
        Price:"",
        Unit:"",
        VDS_CanEdit:false,
        IsexState:0,
        CardTypeState:0,
        Check:false,
        totalPicker:0,
        SchoolItemID:"",
        InsureItemID: "",
        UserID: "",
        StudentID: "",
        helpInsure:false,
        dayCount:"",
        DocList:[],
        ShowInsureProtocol:true,
        html:""
      }
   },
   created(){

   },
   computed:{
   },
   methods:{
    initData(ItemID,SchoolItemID) {

        var currentDate = new Date();  // 获取当前日期
        currentDate.setFullYear(currentDate.getFullYear() - 25);  // 减去25年
        var year = currentDate.getFullYear();  // 获取年份
        this.startBirthDate = new Date(year+"-"+"01"+"-"+"01");

        const params = new URLSearchParams(window.location.search);
        // 获取名为 "param" 的参数值
        this.parameterValue = params.get("SchoolID");

        this.SchoolItemID = SchoolItemID;
        this.InsureItemID = ItemID;
        this.UserID = params.get("UserID");
        this.StudentID = params.get("StudentID");


        this.initPostData();

        let that = this;
        that.$axios.get('https://api.yalijy.com/API/Insure/GetDocTerms?'+"ItemID="+ItemID,
        {}).then(function (resoponse) {

            console.log('------')
            console.log(resoponse)
            if(that.DocList.length > 0)
            {
                that.DocList = []
            }

            for (let item in resoponse.data.data)
            {
                let data = resoponse.data.data[item];
                data.check = false;
                that.DocList.push(data)
            }

            }).catch(function (error){
            console.log(error);
        })

    },
    getDiffDay(date_1, date_2) {
        // 计算两个日期之间的差值
        // let totalDays,diffDate
        // let myDate_1 = Date.parse(date_1)
        // let myDate_2 = Date.parse(date_2)
        // // 将两个日期都转换为毫秒格式，然后做差
        // diffDate = Math.abs(myDate_1 - myDate_2) // 取相差毫秒数的绝对值
        
        // totalDays = Math.floor(diffDate / (1000 * 3600 * 24)) // 向下取整
        // console.log(totalDays)  
        
        var date1 = new Date(date_1);
        var date2 = new Date(date_2);

        // 计算相差的时间（毫秒）
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());

        // 将时间差转换为天数
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        
        return diffDays + 1    // 相差的天数
    },
    GetDateStr(AddDayCount){
        var dd = new Date();
        dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
        var y = dd.getFullYear();
        var m = dd.getMonth()+1;//获取当前月份的日期
        var d = dd.getDate();
        return y+"-"+m+"-"+d;
    },
    clearinitData(){
        this.AidCardNo = "";
        this.AidCardType = "";
        this.Amobile = "";
        this.Aname = "";
        this.ISchoolName = "";
        this.Ibirthday = "";
        this.Iclass = "";
        this.IidCardNo = "";
        this.IidCardType = "";
        this.Iname = "";
        this.Isex = "";
        this.Price = "";
        this.Unit = "";
        this.VDS_CanEdit = true;
        this.ValiDateEnd = "";
        this.ValiDateStart = "";
        this.IsexState = 0;
        this.totalPicker = 0;
        this.helpInsure = true;

        this.initPostData();

    },
    initPostData(){
        
        console.log('initPostData')
        console.log(this.SchoolItemID)

        var today = new Date();
        today.setDate(today.getDate() + 1);

        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();

        // 格式化日期
        this.ValiDateStart = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
        

        var StudentIDNum = ""

        if (this.StudentID != null)
        {
            if (this.StudentID.length > 1)
            {
                StudentIDNum = this.StudentID
            }
        }



        var that = this;
        this.$axios.get('https://api.yalijy.com/api/Insure/GetInsureInf',
        {
        params:{
            SchoolItemID:this.SchoolItemID,
            InsureItemID:that.InsureItemID,
            UserID:"",
            StudentID:StudentIDNum
        }
        }).then(function (resoponse) {
            console.log('-------112');
            console.log(resoponse);
            let data = resoponse.data.data;
            console.log(data);

            if (that.helpInsure)
            {
                that.ISchoolName = "珠海雅礼教育信息技术有限公司";
                that.Price = data.Price;

                // that.ValiDateEnd = data.ValiDateEnd;
                // that.ValiDateStart = data.ValiDateStart;
                // that.ValiDateEnd = that.ValiDateEndt.replace(/\.(\d)\b/g, "-0$1");
                // that.data.ValiDateStart = that.ValiDateStar.replace(/\.(\d)\b/g, "-0$1");

                var parts1 =  data.ValiDateEnd.split('.');
                // 补全月份和日期前导零
                var year1 = parts1[0];
                var month1 = parts1[1].padStart(2, '0');
                var day1 = parts1[2].padStart(2, '0');
                // 拼接成新的日期字符串
                that.ValiDateEnd = `${year1}-${month1}-${day1}`;

                // var parts3 =  data.ValiDateStart.split('.');
                // // 补全月份和日期前导零
                // var year3 = parts3[0];
                // var month3 = parts3[1].padStart(2, '0');
                // var day3 = parts3[2].padStart(2, '0');
                // // 拼接成新的日期字符串
                // that.ValiDateStart = `${year3}-${month3}-${day3}`;

                that.dayCount = that.getDiffDay(that.ValiDateStart,that.ValiDateEnd)
                if (that.dayCount > 0)
                {
                    console.log(that.Price)
                    let p = that.dayCount *  that.Price
                    that.totalPicker = p.toFixed(2);
                }

            }else{
                that.AidCardNo = data.AidCardNo;
                that.AidCardType = data.AidCardType;
                that.Amobile = data.Amobile;
                that.Aname = data.Aname;
                that.Ibirthday = data.Ibirthday;
                that.Iclass = data.Iclass;
                that.IidCardNo = data.IidCardNo;
                that.IidCardType = data.IidCardType;
                that.Iname = data.Iname;
                that.Isex = data.Isex;
                that.Price = data.Price;
                that.Unit = data.Unit;
                that.VDS_CanEdit = data.VDS_CanEdit;
                that.ISchoolName = data.ISchoolName;

                var parts =  data.ValiDateEnd.split('.');
                // 补全月份和日期前导零
                var year = parts[0];
                var month = parts[1].padStart(2, '0');
                var day = parts[2].padStart(2, '0');
                // 拼接成新的日期字符串
                that.ValiDateEnd = `${year}-${month}-${day}`;

                // var parts2 =  data.ValiDateStart.split('.');
                // // 补全月份和日期前导零
                // var year2 = parts2[0];
                // var month2 = parts2[1].padStart(2, '0');
                // var day2 = parts2[2].padStart(2, '0');
                // // 拼接成新的日期字符串
                // that.ValiDateStart = `${year2}-${month2}-${day2}`;

                that.dayCount = that.getDiffDay(that.ValiDateStart,that.ValiDateEnd)
                if (that.dayCount > 0)
                {
                    console.log(that.Price)
                    let p = that.dayCount *  that.Price
                    that.totalPicker = p.toFixed(2);
                }
            }



            if (that.Isex == '男') {
                that.IsexState = 1
            }else if (that.Isex == '女') {
                that.IsexState = 2
            }

        }).catch(function (error){
        console.log(error);
        })

    },
    ClickCheck(){
        this.Check = !this.Check;
    },
    SelectSex (value) {
        this.IsexState = value;
        if (this.IsexState == 1) {
            this.Isex = "男";
        }else if (this.IsexState == 2) {
            this.Isex == "女";
        }
    },
    getsheetData(value){
        console.log(value);
    },
    showSheetVisible(state){
        this.CardTypeState = state;
        this.sheetVisible = true
    },
    getsheet1(){
        if (this.CardTypeState == 1) {
            this.AidCardType = '居民身份证';
        }else if (this.CardTypeState == 2) {
            this.IidCardType = '居民身份证';
        }

    },
    handleInput(){

        var genderDigit = parseInt(this.IidCardNo.charAt(this.IidCardNo.length-2));  // 获取倒数第二位数字

        if (genderDigit % 2 === 0) {
            this.Isex == "女";
            this.IsexState = 2;
        } else {
            this.Isex = "男";
            this.IsexState = 1;
        }
    },
    getsheet2(){
        if (this.CardTypeState == 1) {
            this.AidCardType = '户口本';
        }else if (this.CardTypeState == 2) {
            this.IidCardType = '户口本';
        }
    },
    getsheet3(){

        if (this.CardTypeState == 1) {
            this.AidCardType = '护照';
        }else if (this.CardTypeState == 2) {
            this.IidCardType = '护照';
        }
    },
    getsheet4(){
        if (this.CardTypeState == 1) {
            this.AidCardType = '台胞证';
        }else if (this.CardTypeState == 2) {
            this.IidCardType = '台胞证';
        }
    },
    getsheet5(){
        if (this.CardTypeState == 1) {
            this.AidCardType = '澳门居民身';
        }else if (this.CardTypeState == 2) {
            this.IidCardType = '澳门居民身';
        }
    },
    getsheet6(){
        if (this.CardTypeState == 1) {
            this.AidCardType = '香港居民身份证身';
        }else if (this.CardTypeState == 2) {
            this.IidCardType = '香港居民身份证';
        }
    },
    openPicker() {
        var today = new Date(); // 获取当前日期和时间
        this.startDate =  new Date(today.getTime() + 24 * 60 * 60 * 1000);
        this.endDate = new Date(this.ValiDateEnd);
        this.pickerState = 1;
        this.$refs.startPicker.open();
        // if (this.VDS_CanEdit) {
        // }
    },
    endPicker() {
        // var today = new Date(); // 获取当前日期和时间
        // this.endDate =  new Date((today.getTime() + 48 * 60 * 60 * 1000));
        // this.pickerState = 2;
        // this.$refs.endPicker.open();
        // if (this.VDS_CanEdit) {
        // }
    },
    birthPicker() {
        this.pickerState = 3
        this.$refs.picker.open();
    },
    handleConfirm(value) {

        let data = this.formatDate(value)
        console.log(data);
        console.log(this.pickerState);

        if (this.pickerState == 1) {
            this.ValiDateStart = data;

            this.dayCount = this.getDiffDay(this.ValiDateStart,this.ValiDateEnd)
            if (this.dayCount > 0)
            {
                let p = this.dayCount *  this.Price
                this.totalPicker =  p.toFixed(2);
            }
            
        }else if (this.pickerState == 2)
        {
            this.ValiDateEnd = data;
            this.dayCount = this.getDiffDay(this.ValiDateStart,this.ValiDateEnd)
            if (this.dayCount > 0)
            {
                let p = this.dayCount *  this.Price
                this.totalPicker = p.toFixed(2);
                console.log(this.totalPicker)

            }

        }else if (this.pickerState == 3)
        {
            this.Ibirthday = data;
        }
    },
    formatDate(date) {
        const y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        let d = date.getDate() 
        d = d < 10 ? ('0' + d) : d
        return y +'-'+ m +'-'+ d
    },
    ClickInsure() {


        if (!this.Check)
        {
            MessageBox.alert("请同意,协议才能投保").then(function (){});
            return
        }

        console.log(this.GetDateStr(0));
        console.log("2023-02-06");


        var date1 = new Date();
        var date2 = new Date(this.ValiDateStart);
        var result = date2 > date1;  
        console.log(result) 
        //开始日期大于今日
        if (!result)
        {
            MessageBox.alert("开始保障日期必须大于今天");
            return
        }


        //不能大于结束日期
        var enddate1 = new Date(this.ValiDateStart);
        var enddate2 = new Date(this.ValiDateEnd);
        var endresult = enddate2 > enddate1;  
        console.log(endresult) 
        if (!endresult)
        {
            MessageBox.alert("结束保障日期大于开始保障日期");
            return
        }

        if (this.Aname.length < 0)
        {
            MessageBox.alert("投保人姓名不能为空");
            return
        }

        if (this.AidCardType.length < 1)
        {
            MessageBox.alert("投保人证件不能为空");
            return
        }

        if (this.AidCardNo.length < 1)
        {
            MessageBox.alert("投保人证件号码不能为空");
            return
        }

        if (this.Amobile.length < 1)
        {
            MessageBox.alert("投保人手机号不能为空");
            return
        }
        
        if (this.Iname.length < 1)
        {
            MessageBox.alert("被保人姓名不能为空");
            return
        }

        if (this.IidCardType.length < 1)
        {
            MessageBox.alert("被保人证件类型不能为空");
            return
        }


        if (this.IidCardNo.length < 1)
        {
            MessageBox.alert("被保人证件号不能为空");
            return
        }

        if (this.Ibirthday.length < 1)
        {
            MessageBox.alert("被保人出生日期不能为空");
            return
        }

        if (this.Isex.length < 1)
        {
            MessageBox.alert("被保人性别不能为空");
            return
        }



        Indicator.open('正在加载...')

        var that = this;
        that.$axios({
            url: 'https://api.yalijy.com/api/Insure/AddTransaction',
            method: 'post',
            data: {
                "SchoolItemID": that.SchoolItemID,
                "InsureItemID": that.InsureItemID,
                "UserID": that.UserID,
                "StudentID": that.StudentID,
                "ValiDateStart": that.ValiDateStart+" 00:00:00",
                "ValiDateEnd": that.ValiDateEnd+" 23:59:59",
                "Aname": that.Aname,
                "AidCardType": that.AidCardType,
                "AidCardNo": that.AidCardNo,
                "Amobile": that.Amobile,
                "Iname": that.Iname,
                "IidCardType": that.IidCardType,
                "IidCardNo": that.IidCardNo,
                "Ibirthday": that.Ibirthday,
                "Isex": that.Isex,
                "ISchoolName": that.ISchoolName,
                "Iclass": that.Iclass,
                "Price": that.totalPicker
            }
        })
        .then(function (response) {
            Indicator.close()
            console.log(response.data)
            let data =  response.data
            if (data.flag == 1000)
            {

                that.callParentMethod(data.TranID,data.data);
                // window.location.href = data.data;
            }else{
                MessageBox.alert(data.msg); 
            }
        }).catch(function (err) {
            Indicator.close()
            console.log(err)
        });
    },
    ClickReadProtocol(index) {
        let data = this.DocList[index];
        this.html = data.DocURL;
        this.ShowInsureProtocol = false;
    },
    ClickClose(){
      this.ShowInsureProtocol = true;
    }
    
   },
}
</script>
<style scoped>

.no-touch {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectData
{
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
}

.InsureTitle
{
    margin-top: 10px;
    margin-bottom: 10px;
}

.InsureSubTitle
{
    font-size: 16px;
}
.separateBigDraw
{
    background-color: rgba(215, 214, 214, 0.279);
    /* width: 100%; */
    height: 20px;
}

.separateSmallDraw
{
    background-color: rgba(215, 214, 214, 0.279);
    /* width: 100%; */
    height: 1px;
    margin-top: 10px;
}

.inputView
{
    /* width: 100%; */
    display: flex;
    margin-left: 20px;
    margin-top: 10px;
}

.inputView span
{
    font-size: 15px;
}

.inputView input
{
    width: 60%;
    margin-left: 8px;
    font-size: 15px;
    border: 0px;
    background: white;
}

.inputView input:focus {
outline:none;
border: 0px;
background: white;
}

.selectView
{
    display: flex;
    justify-content: space-between;
    margin-left: 19px;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 15px;
    width: 95%;
}

.selectSexView
{
    display: flex;
    justify-content: space-between;
    margin-left: 19px;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 15px;
    width: 90%;
}

.selectSexImg
{
    width: 20px;
    height: 20px;
}

.sexItem1
{
    margin-left: 10px;
}

.sexItem1 span
{
    margin-left: 10px;
    position: relative;
    top: -5px;
}

.sexItem2
{
    margin-left: 30px;
}

.sexItem2 span
{
    margin-left: 10px;
    position: relative;
    top: -5px;
}


.protocol
{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
}
.protocol img
{
    padding-top: 10px;
    width: 16px;
    height: 16px; 
    margin-top: 10px;
    margin-right: 10px;
    position: relative;
    top: 1px;
    left: 5px;
}

.protocol span
{
    font-size: 18px;
    text-align: left;
}

.footercontainer
{
  flex: 1;
  position: fixed;
  bottom: 0%;
  /* max-width: 640px;
  min-width: 320px; */
  width: 100%;
  z-index: 1000;
}


.footerInsureHome
{
  display: flex;
  background-color: white;
  height: 60px;
}

.footerInsureHome_price
{
  margin-left: 2%;
  margin-top: 10px;
}

.footerInsureHome_price_item1
{
  font-size: 25px;
  color: red;
  font-weight: bold;
}

.footerInsureHome_price_item2
{
  font-size: 15px;
  font-weight: bold;
  margin-left: 3px;
}

.footerInsureHome_operate
{
  position: absolute;
  right: 4%;
  margin-top: 20px;
}

.footerInsureHome_btn1
{

  background-color: red;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
}

.footerInsureHome_btn2
{
  width: 300px;
  height: 45px;
  background-color: red;
  font-size: 15px;
  font-weight: bold;
  padding: 5px;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
}
</style>
