import { render, staticRenderFns } from "./ReadInsure.vue?vue&type=template&id=e3b82790&scoped=true&"
import script from "./ReadInsure.vue?vue&type=script&lang=js&"
export * from "./ReadInsure.vue?vue&type=script&lang=js&"
import style0 from "./ReadInsure.vue?vue&type=style&index=0&id=e3b82790&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3b82790",
  null
  
)

export default component.exports